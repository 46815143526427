// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import '~bootstrap-icons/font/bootstrap-icons';

/*
@import "/resources/css/dashboard.css";
@import "/resources/css/ordersOverviews.css";
@import "/resources/css/reservations.css";
@import "/resources/css/slots.css";
@import "/resources/css/notificationsNavBtn.css";
*/
